import { createContext, useContext } from "react"

export type DirtyContext = {
    isDirty: boolean
    setDirty(): void
    version: object
}

export const DirtyContext = createContext<DirtyContext | null>(null)

export function useDirtyContext() {
    const context = useContext(DirtyContext)
    if (!context) throw new Error("No dirty context")
    return context
}
