import React from "react"
import { ImageToCSS } from "../../reactor/Types/File"
import { UntitledUI } from "../untitled-ui"
import type { Chip } from "./Chip"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Chip>("Chip", ChipWidget)

export function ChipWidget({ value }: { value: Chip }) {
    const colorSeed = value.text || value.icon

    const colors = value.color
        ? ColorStyles[value.color]
        : colorSeed
        ? UntitledUI.randomSecondaryColor(colorSeed)
        : ColorStyles.gray

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div
                style={{
                    backgroundColor: colors[50],
                    color: colors[700],
                    borderRadius: 16,

                    paddingRight: 8,
                    fontSize: 12,
                    fontWeight: 500,
                    marginRight: 2,
                    maxHeight: 30,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                {value.image && (
                    <div
                        style={{
                            display: "flex",
                            width: 24,
                            height: 24,
                            backgroundImage: ImageToCSS(value.image, { width: 24 }),
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            borderRadius: 12,
                        }}
                    />
                )}
                <div style={{ paddingTop: 6, paddingBottom: 6, paddingLeft: 8 }}>
                    {value.icon && (
                        <i
                            className={`fas fa-${value.icon}`}
                            style={{ color: colors[500], marginRight: value.text ? 6 : 0 }}
                        />
                    )}
                    {value.text}
                </div>
            </div>
        </div>
    )
}
