import type { EditorState } from "draft-js"
import React, { CSSProperties } from "react"
import { Overlay } from "react-bootstrap"
import { ImageToUrl } from "../../reactor/Types/File"
import { useHover } from "../hooks/useHover"
import { InsertImage } from "./InsertImage"
import { Modal } from "../modal/Modal"
import { WidgetMap } from "../widgets/Widget"
import { ExtractPropFromWidget } from "../widgets/Helpers"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"
import { Icon } from "../../studio/Views/Icon"
import { useDirtyContext } from "../editing/DirtyContext"
import { ColorStyles } from "../ui"

function ToolbarButton(props: {
    icon?: string
    text?: string
    onClick?: () => void
    style?: CSSProperties
    popup?: (close: () => void) => JSX.Element
    children?: JSX.Element
    primary?: boolean
}) {
    const [elementTarget, setElementTarget] = React.useState<HTMLElement | null>(null)
    const { hover, hoverProps } = useHover()
    const [showPopup, setShowPopup] = React.useState(false)

    const popup = props.popup
    return (
        <>
            {popup && (
                <Overlay target={elementTarget} show={showPopup} placement={"bottom-start"}>
                    {(p) => (
                        <div onMouseLeave={() => setShowPopup(false)} {...p}>
                            {popup(() => setShowPopup(false))}
                        </div>
                    )}
                </Overlay>
            )}
            <div
                ref={setElementTarget}
                {...hoverProps}
                style={{
                    marginBottom: 2,
                    marginTop: 2,
                    padding: 2,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: 4,
                    cursor: "pointer",
                    backgroundColor: hover ? "#ddd" : undefined,
                    color: props.primary ? ColorStyles.primary[600] : undefined,
                    fontSize: 12,
                }}
                // This prevents the markdown editor from losing focus when
                // clicking on the toolbar
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => {
                    if (props.popup) setShowPopup(!showPopup)
                    if (props.onClick) props.onClick()
                }}>
                {props.icon && (
                    <Icon icon={props.icon} size={12} style={{ marginRight: props.text ? 4 : 0 }} />
                )}
                {props.text && (
                    <span onMouseEnter={() => setShowPopup(true)} style={props.style}>
                        {props.text}
                    </span>
                )}
                {props.children}
            </div>
        </>
    )
}
function ToolbarSeparator() {
    return (
        <div
            style={{
                width: 1,
                marginLeft: 4,
                marginRight: 4,
                height: 16,
                backgroundColor: "#aaa",
                alignSelf: "center",
            }}
        />
    )
}

export function Toolbar({
    currentMarkdownSnippet,
    editorState,
    setEditorState,
    draftJs,
    style,
    onDone,
}: {
    currentMarkdownSnippet?: Uuid<"MarkdownSnippet">
    editorState: EditorState
    setEditorState: (editorState: EditorState) => void
    draftJs: typeof import("draft-js")
    style: "studio" | "wysiwyg"
    /** If set, the toolbar will display a "Done"-button which calls this method
     * when clicked. This is typically used to de-focus the editor. */
    onDone?: () => void
}) {
    const { setDirty } = useDirtyContext()

    async function pickFile() {
        const { DocumentPicker } = await import("../../studio/Views/DocumentPicker")

        const doc = await Modal<WidgetMap>((close) => (
            <DocumentPicker
                collection="FileRecords"
                current={undefined}
                cancel={() => close(undefined)}
                itemName="Image"
                itemSelected={(row) => close(row)}
            />
        ))
        if (doc) {
            const name = ExtractPropFromWidget(doc, "primary")
            const id = doc._primaryKey?.toString()
            if (id && name) return { id: id as any as Uuid, name }
        }
    }

    async function pickSnippet() {
        const { DocumentPicker } = await import("../../studio/Views/DocumentPicker")

        const doc = await Modal<WidgetMap>((close) => (
            <DocumentPicker
                collection="MarkdownSnippets"
                current={undefined}
                cancel={() => close(undefined)}
                itemName="MarkdownSnippet"
                itemSelected={(row) => close(row)}
                excludePrimaryKeys={
                    // Avoid making it easy to create a circular reference
                    currentMarkdownSnippet ? [currentMarkdownSnippet.valueOf()] : undefined
                }
            />
        ))
        if (doc) {
            const name = ExtractPropFromWidget(doc, "primary")
            const id = doc._primaryKey?.toString()
            if (id && name) return { id: id as any as Uuid, name }
        }
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid #ddd",
                backgroundColor: "#f8f8f8",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                position: style === "wysiwyg" ? "absolute" : undefined,
                bottom: style === "wysiwyg" ? "100%" : undefined,
                boxShadow: style === "wysiwyg" ? "0px 0px 4px 0px rgba(0,0,0,0.2)" : undefined,
                color: "#333",
                paddingLeft: 3,
                paddingRight: 3,
            }}>
            <ToolbarButton
                text="Heading"
                popup={(close) => (
                    <div
                        style={{
                            backgroundColor: "#fff",
                            padding: 8,
                            boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.2)",
                            zIndex: 100,
                        }}>
                        <ToolbarButton
                            onClick={() => {
                                setEditorState(
                                    draftJs.RichUtils.toggleBlockType(editorState, "header-two")
                                )
                                close()
                            }}>
                            <h2>Heading 2</h2>
                        </ToolbarButton>
                        <ToolbarButton
                            onClick={() => {
                                setEditorState(
                                    draftJs.RichUtils.toggleBlockType(editorState, "header-three")
                                )
                                close()
                            }}>
                            <h3>Heading 3</h3>
                        </ToolbarButton>
                        <ToolbarButton
                            onClick={() => {
                                setEditorState(
                                    draftJs.RichUtils.toggleBlockType(editorState, "header-four")
                                )
                                close()
                            }}>
                            <h4>Heading 4</h4>
                        </ToolbarButton>
                        <ToolbarButton
                            onClick={() => {
                                // Remove heading style
                                setEditorState(
                                    draftJs.RichUtils.toggleBlockType(editorState, "unstyled")
                                )
                                close()
                            }}>
                            <>Regular text</>
                        </ToolbarButton>
                    </div>
                )}
                style={{ marginTop: 9 }}
            />
            <ToolbarSeparator />
            <ToolbarButton
                icon="ui-bold-01"
                onClick={() =>
                    setEditorState(draftJs.RichUtils.toggleInlineStyle(editorState, "BOLD"))
                }
            />
            <ToolbarButton
                icon="ui-italic-01"
                onClick={() =>
                    setEditorState(draftJs.RichUtils.toggleInlineStyle(editorState, "ITALIC"))
                }
            />
            <ToolbarButton
                icon="ui-underline-01"
                style={{ marginTop: 9 }}
                onClick={() =>
                    setEditorState(draftJs.RichUtils.toggleInlineStyle(editorState, "UNDERLINE"))
                }
            />
            <ToolbarSeparator />
            <ToolbarButton
                icon="ui-dotpoints-01"
                style={{ marginTop: 9 }}
                onClick={() =>
                    setEditorState(
                        draftJs.RichUtils.toggleBlockType(editorState, "unordered-list-item")
                    )
                }
            />

            <ToolbarButton
                icon="ui-image-01"
                style={{ marginTop: 9 }}
                onClick={async () => {
                    const file = await pickFile()
                    if (!file) return

                    setEditorState(
                        InsertImage(draftJs, editorState, ImageToUrl(file.id as any), file.name)
                    )
                    setDirty()
                }}
            />
            <ToolbarButton
                icon="ui-sticker-square"
                style={{ marginTop: 9 }}
                onClick={async () => {
                    const snippet = await pickSnippet()
                    if (!snippet) return

                    setEditorState(
                        InsertImage(draftJs, editorState, snippet.id as any, "#MARKDOWN_SNIPPET")
                    )
                    setDirty()
                }}
            />
            {onDone && (
                <>
                    <ToolbarSeparator />
                    <ToolbarButton icon="ui-check" text="Done" primary={true} onClick={onDone} />
                </>
            )}
        </div>
    )
}
