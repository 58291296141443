import { IsNode } from "./reactor/AssertNode"

// This hack allows us to not have a hard dependency on async-hooks on the
// client side
let getServer = () => "<not-initialized>"
if (IsNode()) {
    getServer = (globalThis as any).ReactorContext?.getServer
}

export function server(
    /** If set to `true`, the local IP address will be used instead of localhost
     * when a local browser is accessing the local server.
     *
     * This makes it possible for external services (e.g. Gotenberg) to access
     * the local server.
     */
    preferLocalIP = false
): string {
    const srv = IsNode() ? getServer() : window.location.origin

    // This hack allows us to run web front ends on port 3002 with parcel, while the
    // server itself runs on 3000
    if (srv.endsWith("3002")) {
        return srv.slice(0, srv.length - "3002".length) + "3000"
    }

    if (preferLocalIP && srv === "http://localhost:3000") {
        // In Node, this is set by `Context.ts`, to give access to the local
        // HTTP server through the local network IP address
        if ("localHttpServer" in globalThis) {
            return (globalThis as any).localHttpServer
        }
    }

    return srv
}
;(globalThis as any).server = server
