/** A plain `string` masquerading as an object to help typescript distinguish between them.
 *  To create an opaque string type, do:
 *
 * ```ts
 * export type MyOpaqueType = OpaqueString<"MyOpaqueType">
 * ```
 */
export declare class OpaqueString<T extends string> extends String {
    /** This helps typescript identify this type as an opaque type */
    readonly __opaque: undefined

    /** This helps typescript distinguish different opaque string types. */
    readonly __opaqueString: T
    /** This object is already an opaque a string, but calling this makes method
     * makes typescript recognize it as a string. */
    toString(): string
}

export declare class OpaqueNumber<T extends string> extends Number {
    /** This helps typescript identify this type as an opaque type */
    readonly __opaque: undefined

    protected readonly __opaqueNumber: T
}
export declare class OpaqueInteger<T extends string> extends OpaqueNumber<T> {
    /** This helps typescript identify this type as an opaque type */
    readonly __opaque: undefined

    protected readonly __integer: T
}

export declare class RangedNumber<
    T extends string,
    minValue extends number | undefined,
    maxValue extends number | undefined
> extends OpaqueNumber<T> {
    protected readonly __minValue: minValue
    protected readonly __maxValue: maxValue
}

export declare class RangedInteger<
    T extends string,
    minValue extends number | undefined,
    maxValue extends number | undefined
> extends RangedNumber<T, minValue, maxValue> {
    protected readonly __integer: T
    protected readonly __minValue: minValue
    protected readonly __maxValue: maxValue
}
