import React from "react"
import ReactDOM from "react-dom"

/** Initializes a web app, making sure it works well in both production and HMR mode. */
export function InitWebApp(
    RootComponent: React.FunctionComponent,
    /** ID of the DOM root element */
    rootId = "root"
) {
    ReactDOM.hydrate(<RootComponent />, document.getElementById(rootId))
}
