import React from "react"
import { Col, Row } from "react-bootstrap"
import { EditableText } from "../../../../packages/editing/EditableText"
import { ImageToCSS } from "../../../../reactor/Types/File"
import { useMenuContext } from "../MenuContext"
import { colors } from "../styles"
import { Section } from "../../../../packages/editing/Section"

Section("Footer", Footer)
export function Footer() {
    const { footer } = useMenuContext()
    if (!footer) return <></>

    return (
        <Row
            style={{
                backgroundColor: "#0B3540",
                display: "flex",
                flexDirection: "row",
                color: "white",
                justifyContent: "center",
                flexWrap: "wrap",
                marginTop: 64,
            }}>
            {footer.image && (
                <div
                    style={{
                        padding: 48,
                        width: 225,
                        height: 225,
                        alignSelf: "center",
                        opacity: 0.2,
                        backgroundImage: ImageToCSS(footer.image, {
                            width: 225,
                            height: 225,
                        }),
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
            )}
            <Col style={{ width: "100%", maxWidth: 900, padding: "4rem", alignSelf: "center" }}>
                <EditableText
                    style={{ fontSize: 32, color: "white" }}
                    obj={footer}
                    prop="logoText"
                />
                <EditableText
                    style={{ color: colors.mediumGreen, fontSize: 18, fontWeight: "bold" }}
                    obj={footer}
                    prop="subText"
                />

                <Row style={{ flex: 1, marginTop: 16 }}>
                    <EditableText obj={footer} prop="address" style={{ flex: 1, color: "white" }} />
                    <Col style={{ flex: 1 }}>
                        {footer.links.map((l) => (
                            <EditableText
                                key={l.text}
                                style={{ textDecoration: "underline" }}
                                obj={l}
                                prop="text"
                                link={l.link.toString()}
                            />
                        ))}
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <EditableText
                            key="socialLinks"
                            style={{ textDecoration: "underline" }}
                            obj={footer}
                            isMarkdown={true}
                            prop="socialLinks"
                            defaultText="Follow us on Twitter"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
