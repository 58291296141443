import { useEffect, useState } from "react"

/** Reflects the state after it has been stable for the specified number of milliseconds. */
export function useDebounce<T>(state: T, milliseconds = 300) {
    const [debounced, setDebounced] = useState(state)
    useEffect(() => {
        const timeout = setTimeout(() => setDebounced(state), milliseconds)
        return () => clearTimeout(timeout)
    }, [state, milliseconds])
    return debounced
}

/** Same as `useDebounce`, but observes the JSON-stringified content of the provided object instead
 * of the reference. */
export function useDebounceContent<T>(object: T, milliseconds = 500): T {
    const str = JSON.stringify(object)
    const res = useDebounce(str, milliseconds)
    if (res === str) return object
    else return JSON.parse(res)
}
