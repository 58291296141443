import { BadRequest } from "../../ErrorCodes"
import { OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/**
 * @shared
 */
export type PhoneNumber = OpaqueString<"PhoneNumber">
TypeValidator(PhoneNumber)
export function PhoneNumber(pn: string): PhoneNumber {
    return pn as any
}

export class InvalidPhoneNumberFormat extends BadRequest {
    constructor() {
        super(
            "Phone number is not in a valid format - Requires the phone number to be in E.164 format, with a leading +"
        )
    }
}
