import { OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/** Represents a well-formed email address
 *
 *  @shared
 */
export type Email = OpaqueString<"Email">

TypeValidator(Email)
/** Validates that a string is a well-formed email */
export function Email(email: string): Email {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return email as any
    }

    throw new Error("Invalid email address")
}

/** Abbreviates the email address, e.g. "foo.bar@email.com" => "FB" */
Email.abbreviate = (e: Email, maxLength = 2) => {
    try {
        const i = e.indexOf("@")
        const n = e.slice(0, i)
        const r = n.split(/[^A-Za-z]/)
        return r
            .slice(0, maxLength)
            .map((x) => x[0].toUpperCase())
            .join("")
    } catch {
        return e.charAt(0)
    }
}
