import React, { CSSProperties, useState } from "react"
import { EditableText } from "../../../packages/editing/EditableText"
import { useParam } from "../../../packages/ssr/useParam"
import { WidgetAPIView } from "../../../packages/widgets/WidgetAPIView"
import { Uuid } from "../../../reactor/Types/Primitives"
import {
    DenominatorUnit,
    GetOfferingReportDto,
    GetOfferingReportOfferingExecutiveSummaryDto,
    GetOfferingReportOfferingProjectionSummaryDto,
    GetOfferingReportQuantitativeResultsDto,
    GetOfferingReportTextForExecutiveSummaryProjections1Dto,
    ProcessItem,
    useOfferingReport,
    useUserPrivileges,
} from "./client"
import { Chart } from "chart.js"
import { colors } from "./styles"
import { Image, ImageToUrl } from "../../../reactor/Types/File"

import { WidgetView } from "../../../packages/widgets/WidgetView"
import { capitalizeFirstLetter, lowercaseFirstLetter } from "../../../reactor/Helpers"
import { ImpactPriority, ImpactScore } from "../Offering"
import { useDirtyContext } from "../../../packages/editing/DirtyContext"
import { useEditableContext } from "../../../packages/editing/EditableContext"
import { Footer } from "./sections/Footer"
import { useChangeNotifications } from "../../../reactor/Web"
import { ColorStyles } from "../../../packages/ui"
import { HeaderSection } from "./sections/Header"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

Chart.defaults.font.family = "Jost"
Chart.defaults.font.size = 16

export function OfferingReportView() {
    const reportId = useParam("offeringId") as any as Uuid<"Offering">
    const { data: report, refresh } = useOfferingReport(reportId)
    const { data: privileges } = useUserPrivileges()
    useChangeNotifications("Offerings", refresh, !!privileges?.canEditWebsite)

    if (!report) return <></>

    const {
        offering,
        offeringUnit,
        firstPublished,
        lastModified,
        textForExecutiveSummaryProjections,
    } = report

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                }}>
                <HeaderSection size="compact" />

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        style={{
                            position: "absolute",
                            right: 32,
                            marginTop: 32,
                            fontSize: 16,
                            fontWeight: "normal",
                            color: "#888",
                        }}>
                        <div style={{ textAlign: "right" }}>First published: {firstPublished}</div>
                        <div style={{ textAlign: "right" }}>Last modified: {lastModified}</div>
                    </div>
                </div>

                <div style={{ width: 1280, maxWidth: "" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            color: colors.darkGreen,
                        }}>
                        <div style={{ marginTop: 32, fontSize: 20 }}>{offering.usage}</div>
                        <div
                            style={{
                                marginTop: 8,
                                marginBottom: 8,
                                fontSize: 36,
                                fontWeight: "bold",
                            }}>
                            {offering.name}
                        </div>
                        <div style={{ fontSize: 24 }}>{offering.secondaryName}</div>
                        <div style={{ margin: 20, fontSize: 20 }}>
                            {offering.typeOfProductOrService &&
                                offering.typeOfProductOrService + " Solution"}
                        </div>
                        <div
                            style={{
                                marginTop: 32,
                                marginBottom: 5,
                                fontSize: 16,
                                fontWeight: "normal",
                                color: "#888",
                            }}>
                            <a
                                onClick={(e) => {
                                    if (report.account?.slug) {
                                        window.open(
                                            `/accounts/${report.account?.slug?.toLocaleLowerCase()}`
                                        )
                                    } else {
                                        window.open(
                                            `/accounts/${report.account?.id?.toLocaleLowerCase()}`
                                        )
                                    }
                                }}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}>
                                <EditableText
                                    obj={report.offering}
                                    prop="attributionPrefix"
                                    defaultText="A solution by"
                                />

                                <div
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: 4,
                                        textDecoration: "underline",
                                        color: "#61A391",
                                        fontWeight: "500",
                                    }}>
                                    {report.account?.name}
                                </div>
                            </a>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", margin: 32 }}>
                        {offering.image?.valueOf() && (
                            <img
                                src={ImageToUrl(offering.image?.valueOf())}
                                alt="image"
                                style={{
                                    flex: 1,
                                    flexDirection: "column",
                                    objectFit: "cover",
                                    padding: 32,
                                    maxWidth: "50%",
                                }}
                            />
                        )}
                        <div style={{ flex: 1, flexDirection: "column", padding: 32 }}>
                            <div
                                style={{
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    color: colors.darkGreen,
                                    marginBottom: 16,
                                }}>
                                Overview
                            </div>
                            {offering.overview && (
                                <EditableText
                                    obj={offering}
                                    isMarkdown={true}
                                    prop="overview"
                                    defaultText="(empty)"
                                    style={{}}
                                    placeholder="Overview goes here"
                                />
                            )}
                        </div>
                    </div>

                    <div style={{ margin: 32, display: "flex", flexDirection: "column" }}>
                        <div style={header2}>Analysis Parameters</div>
                        <div style={{ marginLeft: 32, marginRight: 32, maxWidth: 1280 }}>
                            <div style={header3}>
                                {report.textForAnalysisParameters?.outliningImpactActionHeader}
                            </div>
                            {report.textForAnalysisParameters && (
                                <MarkdownView
                                    value={
                                        report.textForAnalysisParameters.outliningImpactActionText
                                    }
                                />
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: 32,
                                marginTop: -30,
                            }}>
                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                <div style={header3}>Functional Unit</div>

                                <WidgetView
                                    value={{
                                        type: "Item",
                                        icon: "balance-scale",
                                        primary:
                                            capitalizeFirstLetter(offeringUnit.name) +
                                            " of " +
                                            lowercaseFirstLetter(offering.name),
                                    }}
                                />
                                <div style={{ marginTop: 32 }}>
                                    <EditableText
                                        isMarkdown={true}
                                        obj={offering}
                                        prop="unitDescription"
                                        defaultText="(empty)"
                                    />
                                </div>
                                <b>Reference flow</b>

                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="referenceFlow"
                                    defaultText="(empty)"
                                />

                                <div style={header3}>Goal and scope</div>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="goal"
                                    defaultText="(empty)"
                                />
                                <b>Reason for study</b>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="reasonForStudy"
                                    defaultText="(empty)"
                                />
                                <b>Audience</b>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="audience"
                                    defaultText="(empty)"
                                />
                            </div>
                            <div style={{ width: 64 }} />
                            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                <div style={header3}>System Boundary</div>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="systemBoundaryDescription"
                                    defaultText="(empty)"
                                />

                                <b>Technology Coverage</b>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="technologyCoverage"
                                    defaultText="(empty)"
                                />
                                <b>Geographical boundary</b>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="geographicalBoundary"
                                    defaultText="(empty)"
                                />
                                <b>Exclusions</b>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="exclusions"
                                    defaultText="(empty)"
                                />
                                <b>Temporal coverage</b>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="temporalCoverage"
                                    defaultText="(empty)"
                                />

                                <div style={header3}>Lifetime</div>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="lifetimeDescription"
                                    defaultText="(empty)"
                                />
                            </div>
                        </div>
                    </div>

                    {offering.executiveSummary && (
                        <ExecutiveSummary
                            offeringId={offering.id}
                            summary={offering.executiveSummary}
                            projectionSummary={offering.projectionSummary}
                            textForExecutiveSummaryProjections={textForExecutiveSummaryProjections}
                        />
                    )}

                    <div>
                        <div style={{ margin: 32, display: "flex", flexDirection: "column" }}>
                            <div style={header2}>Process Overview</div>
                            {ReportSettingsTexts(
                                report.textForProcessOverview,
                                report.processOverviewDiagram
                            )}
                            <div style={{ marginLeft: 16, marginRight: 16 }}>
                                <WidgetAPIView
                                    param={offering.id.valueOf()}
                                    name="ProcessOverviewChartWithPickers"
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ margin: 32, display: "flex", flexDirection: "column" }}>
                        <div style={header2}>Scope Allocation Analysis</div>
                        {ReportSettingsTexts(
                            report.textForScopeAllocationAnalysis,
                            report.scopeAllocationDiagram
                        )}
                        {ProcessColumnHeader()}
                        <div style={{ flexDirection: "row", alignItems: "center" }}>
                            <div
                                style={{ flex: 1, height: 2, backgroundColor: colors.darkGreen }}
                            />
                        </div>
                        {offering.process.map((p, i) => {
                            return (
                                <ProcessItemView
                                    key={p.name}
                                    index={i}
                                    processItem={p}
                                    quantitativeResults={report.quantitativeResults[i]}
                                    unit={report.functionalUnit}
                                    report={report}
                                />
                            )
                        })}
                        <div style={{ flexDirection: "row", alignItems: "center", marginTop: 32 }}>
                            <div
                                style={{ flex: 1, height: 2, backgroundColor: colors.darkGreen }}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            margin: 32,
                            display: "flex",
                            flexDirection: "column",
                        }}>
                        <div style={header2}>Impact Aggregation</div>
                        <div style={{ marginLeft: 32, marginRight: 32, maxWidth: 1280 }}>
                            <div style={header3}>
                                {report.textForScopeAggregation?.outliningImpactActionHeader}
                            </div>
                            {report.textForScopeAggregation && (
                                <div>
                                    <MarkdownView
                                        value={
                                            report.textForScopeAggregation.outliningImpactActionText
                                        }
                                    />
                                </div>
                            )}
                            <div style={{ marginTop: 32 }}>
                                <WidgetAPIView
                                    param={offering.id.valueOf()}
                                    name="ImpactByScopeProcessItemsChart"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            margin: 32,
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 1280,
                        }}>
                        <div style={header2}>Projections</div>
                        {ReportSettingsTexts(report.textForProjection, report.projectionDiagram)}
                        <div style={{ marginLeft: 16, marginRight: 16 }}>
                            <WidgetAPIView
                                param={offering.id.valueOf()}
                                name="ProductionProjectionTab"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

const lightYellowBg = ColorStyles.yellow[100]
const lightOrangeBg = ColorStyles.orange[50]
const lightGreenBg = ColorStyles.green[100]
const lightBlueBg = ColorStyles.blue[100]

const header1 = { fontSize: 36, marginTop: 32, fontWeight: "bold" }
const header2: CSSProperties = {
    marginTop: 64,
    marginBottom: 16,
    fontSize: 32,
    alignSelf: "center",
    textAlign: "center",
    color: colors.darkGreen,
}
const header3: CSSProperties = {
    marginTop: 32,
    marginBottom: 16,
    fontSize: 20,
    fontWeight: "bold",
    color: colors.darkGreen,
}
const paragraph: CSSProperties = {
    padding: 32,
}

function ExecutiveSummary({
    offeringId,
    summary,
    projectionSummary,
    textForExecutiveSummaryProjections,
}: {
    offeringId: Uuid<"Offering">
    summary: GetOfferingReportOfferingExecutiveSummaryDto
    projectionSummary?: GetOfferingReportOfferingProjectionSummaryDto
    textForExecutiveSummaryProjections?: GetOfferingReportTextForExecutiveSummaryProjections1Dto
}) {
    return (
        <div style={{ margin: 32, display: "flex", flexDirection: "column" }}>
            <div style={header2}>Executive Summary</div>

            <div style={{ display: "flex", flexDirection: "row", padding: 32, marginTop: -30 }}>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>Key revelations</div>
                    <EditableText
                        isMarkdown={true}
                        obj={summary}
                        prop="keyRevelations"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Insights to Impact Strategy</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="insightsToImpactStrategy"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Offering Projections</div>

                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="offeringProjections"
                        defaultText="(empty)"
                    />
                    <div style={{ marginTop: 32 }}>
                        <WidgetAPIView
                            name="ProductionProjectionChart"
                            param={offeringId.valueOf()}
                        />
                    </div>
                </div>
                <div style={{ width: 64 }} />

                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>Potential Challenges</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="potentialChallenges"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Possible Rebounds</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="possibleRebounds"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Climate Value Proposition</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="climateValueProposition"
                        defaultText="(empty)"
                    />
                    <div style={{ marginTop: 32 }}>
                        <WidgetAPIView name="ImpactByScopeChart" param={offeringId.valueOf()} />
                    </div>
                </div>
            </div>
            {projectionSummary && (
                <div
                    style={{
                        paddingLeft: 32,
                        paddingRight: 32,
                        paddingBottom: 32,
                        borderRadius: 8,
                    }}>
                    <Tabs
                        tabs={{
                            Annual: (
                                <>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ flex: 2, marginRight: 32, marginLeft: 16 }}>
                                            <div style={header3}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.annualProjections.header
                                                }
                                            </div>

                                            <div style={{ marginBottom: 16 }}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.annualProjections.text
                                                }
                                            </div>
                                            <EditableText
                                                obj={projectionSummary}
                                                isMarkdown={true}
                                                prop="annualEmissions"
                                                defaultText="(empty)"
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 3,
                                            }}>
                                            <WidgetAPIView
                                                name="ImpactAggregationChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <WidgetAPIView
                                            name="ImpactAggregationTable"
                                            param={offeringId.valueOf()}
                                        />
                                    </div>
                                </>
                            ),
                            Cumulative: (
                                <>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ flex: 2, marginRight: 32, marginLeft: 16 }}>
                                            <div style={header3}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.cumulativeProjections.header
                                                }
                                            </div>

                                            <div style={{ marginBottom: 16 }}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.cumulativeProjections.text
                                                }
                                            </div>
                                            <EditableText
                                                obj={projectionSummary}
                                                isMarkdown={true}
                                                prop="cumulativeEmissions"
                                                defaultText="(empty)"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 3,
                                            }}>
                                            <WidgetAPIView
                                                name="CumulativeImpactChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <WidgetAPIView
                                            name="CumulativeImpactTable"
                                            param={offeringId.valueOf()}
                                        />
                                    </div>
                                </>
                            ),

                            "Reaching the ClimatePoint": (
                                <>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ flex: 2, marginRight: 32, marginLeft: 16 }}>
                                            <div style={header3}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.reachingTheClimatePoint.header
                                                }
                                            </div>

                                            <div style={{ marginBottom: 16 }}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.reachingTheClimatePoint.text
                                                }
                                            </div>

                                            <EditableText
                                                obj={projectionSummary}
                                                isMarkdown={true}
                                                prop="reachingTheClimatePoint"
                                                defaultText="(empty)"
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 3,
                                            }}>
                                            <WidgetAPIView
                                                name="AmortizedImpactChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <WidgetAPIView
                                            name="AmortizedImpactTable"
                                            param={offeringId.valueOf()}
                                        />
                                    </div>
                                </>
                            ),

                            "Return on Impact": (
                                <>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ flex: 2, marginRight: 32, marginLeft: 16 }}>
                                            <div style={header3}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.returnOnImpact.header
                                                }
                                            </div>

                                            <div style={{ marginBottom: 16 }}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.returnOnImpact.text
                                                }
                                            </div>

                                            <EditableText
                                                obj={projectionSummary}
                                                isMarkdown={true}
                                                prop="returnOnImpact"
                                                defaultText="(empty)"
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 3,
                                                margin: 16,
                                            }}>
                                            <WidgetAPIView
                                                name="ReturnOnImpactChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <WidgetAPIView
                                            name="ReturnOnImpactTable"
                                            param={offeringId.valueOf()}
                                        />
                                    </div>
                                </>
                            ),
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export function Tabs({ tabs }: { tabs: { [title: string]: JSX.Element } }) {
    const [current, setCurrent] = useState(Object.keys(tabs)[0])

    return (
        <div style={{ marginTop: 64 }}>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: 32 }}>
                {Object.keys(tabs).map((title, i) => (
                    <div
                        style={{
                            flex: 1,
                            textAlign: "center",
                            padding: 8,
                            backgroundColor:
                                current === title
                                    ? ColorStyles.primary[700]
                                    : ColorStyles.primary[100],
                            color: current === title ? "white" : ColorStyles.primary[800],
                            borderRadius: 8,
                            marginLeft: i > 0 ? 16 : 0,
                            userSelect: "none",
                            cursor: "pointer",
                        }}
                        onClick={() => setCurrent(title)}>
                        {title}
                    </div>
                ))}
            </div>

            {tabs[current]}
        </div>
    )
}

export function impactScoreColor(s: ImpactScore) {
    switch (s) {
        case "Aligned":
            return colors.primaryGreen
        case "Negative":
            return colors.red
        case "Potential":
            return colors.orange
        case "Rebound":
            return colors.orange
        case "None":
            return colors.green
    }
}
export function impactPriorityColor(s: ImpactPriority) {
    switch (s) {
        case "High":
            return colors.red
        case "Medium":
            return colors.orange
        case "Low":
            return colors.primaryGreen
        case "None":
            return colors.green
    }
}

function ReportSettingsTexts(textObject?: any, diagram?: Image) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {diagram && (
                <img
                    src={ImageToUrl(diagram)}
                    alt="image"
                    style={{
                        marginTop: 32,
                        marginBottom: 32,
                        objectPosition: "center",
                        height: 300,
                        objectFit: "contain",
                    }}
                />
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 32,
                    marginTop: -30,
                }}>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>{textObject?.backgroundInformationHeader}</div>
                    {textObject && <MarkdownView value={textObject.backgroundInformationText} />}
                </div>
                <div style={{ width: 64 }} />
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>{textObject?.outliningImpactActionHeader}</div>
                    {textObject && <MarkdownView value={textObject.outliningImpactActionText} />}
                </div>
            </div>
        </div>
    )
}

function ProcessColumnHeader() {
    return (
        <div
            style={{
                marginTop: 32,
                display: "flex",
                flexDirection: "column",
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 8,
                    height: 64,
                    cursor: "pointer",
                }}>
                <div
                    style={{
                        height: "100%",
                        width: 64,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        fontSize: 26,
                    }}>
                    <div
                        style={{
                            color: colors.darkGreen,
                            fontWeight: "bold",
                        }}>
                        #
                    </div>
                </div>
                <div
                    style={{
                        fontSize: 24,
                        padding: 16,
                        color: colors.darkGreen,
                        flex: 3,
                        fontWeight: "bold",
                    }}>
                    Emission impact factor
                </div>
                <div
                    style={{
                        flex: 1.5,
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}>
                    {"Scope"}
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}>
                    Score
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: 60,
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}>
                    Priority
                </div>
            </div>
        </div>
    )
}

function ProcessItemView({
    processItem: pi,
    report,
    index,
    quantitativeResults,
    unit,
}: {
    processItem: ProcessItem
    report: GetOfferingReportDto
    index: number
    quantitativeResults: GetOfferingReportQuantitativeResultsDto[]
    unit: DenominatorUnit | undefined
}) {
    const [expanded, setExpanded] = useState(false)
    const { setDirty } = useDirtyContext()
    const { editing } = useEditableContext()
    return (
        <div
            style={{
                marginTop: 32,
                display: "flex",
                flexDirection: "column",
            }}>
            <div
                onClick={() => setExpanded(!expanded)}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#EEEFF1",
                    alignItems: "center",
                    borderRadius: 8,
                    height: 64,
                    cursor: "pointer",
                }}>
                <div
                    style={{
                        backgroundColor:
                            pi.parentScope === "Scope 1"
                                ? colors.peach
                                : pi.parentScope === "Scope 2"
                                ? colors.darkGreen
                                : colors.primaryGreen,
                        height: "100%",
                        width: 64,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: 26,
                    }}>
                    {index + 1}
                </div>
                <EditableText
                    obj={pi}
                    prop="name"
                    style={{ fontSize: 26, padding: 16, color: colors.darkGreen, flex: 3 }}
                />
                <div style={{ flex: 1.5 }}>
                    {pi.parentScope} {pi.childScope}
                </div>

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            width: 9,
                            height: 9,
                            borderRadius: 6,
                            backgroundColor: impactScoreColor(
                                pi.qualitativeOverview?.impactScore || "None"
                            ),
                            marginRight: 8,
                        }}
                    />{" "}
                    {pi.qualitativeOverview.impactScore}
                </div>

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                    <div
                        style={{
                            width: 9,
                            height: 9,
                            borderRadius: 6,
                            backgroundColor: impactPriorityColor(
                                pi.qualitativeOverview?.priority || "None"
                            ),
                            marginRight: 8,
                        }}
                    />{" "}
                    {pi.qualitativeOverview.priority}
                </div>
                <i
                    className={`fas fa-chevron-${expanded ? "up" : "down"}`}
                    style={{
                        flex: 0.15,
                        paddingRight: 32,
                        textAlign: "center",
                    }}
                />
            </div>
            {expanded && (
                <>
                    {pi.qualitativeOverview && pi.qualitativeOverview.impactScore !== "None" && (
                        <div
                            style={{
                                color: "#888",
                                backgroundColor: "#f8f8f8",
                                padding: 16,
                                margin: 16,
                                marginTop: 32,
                                marginBottom: 5,
                                borderRadius: 8,
                            }}>
                            <div style={{ display: "flex" }}>
                                <div
                                    style={{
                                        width: 9,
                                        height: 9,
                                        borderRadius: 6,
                                        marginLeft: 4,
                                        backgroundColor: impactScoreColor(
                                            pi.qualitativeOverview?.impactScore || "None"
                                        ),
                                        marginRight: 8,
                                        marginTop: 5,
                                    }}
                                />
                                <div style={{ fontWeight: "500" }}>
                                    Score: {pi.qualitativeOverview.impactScore}
                                </div>
                            </div>
                            <EditableText
                                obj={pi.qualitativeOverview}
                                isMarkdown={true}
                                prop="impactScoreComment"
                                placeholder="empty score comment"
                                style={{ marginTop: 0, marginLeft: 4 }}
                            />
                        </div>
                    )}
                    {pi.qualitativeOverview && pi.qualitativeOverview.priority !== "None" && (
                        <div
                            style={{
                                color: "#888",
                                backgroundColor: "#f8f8f8",
                                padding: 16,
                                margin: 16,
                                marginTop: 10,
                                borderRadius: 8,
                            }}>
                            <div style={{ display: "flex" }}>
                                <div
                                    style={{
                                        width: 9,
                                        height: 9,
                                        borderRadius: 6,
                                        marginLeft: 4,
                                        backgroundColor: impactPriorityColor(
                                            pi.qualitativeOverview?.priority || "None"
                                        ),
                                        marginRight: 8,
                                        marginTop: 5,
                                    }}
                                />
                                <div style={{ fontWeight: "500" }}>
                                    Priority: {pi.qualitativeOverview.priority}
                                </div>
                            </div>
                            <EditableText
                                obj={pi.qualitativeOverview}
                                isMarkdown={true}
                                prop="priorityComment"
                                placeholder="empty priority comment"
                                style={{ marginTop: 0, marginLeft: 4 }}
                            />
                        </div>
                    )}

                    <div style={{ marginTop: 48, fontSize: 20 }}>Qualitative Overview</div>

                    <div
                        style={{
                            backgroundColor: lightYellowBg,
                            padding: 32,
                            marginTop: 16,
                            borderRadius: 8,
                        }}>
                        <div style={{ fontWeight: "bold" }}>Company Claim</div>
                        <EditableText
                            obj={pi.qualitativeOverview}
                            isMarkdown={true}
                            prop="companyClaim"
                            defaultText="Company claim goes here"
                            style={{ fontStyle: "italic", marginTop: 8 }}
                        />
                    </div>
                    {editing || pi.qualitativeOverview.benchmarks ? (
                        <div style={{ marginTop: 16, padding: 32 }}>
                            <div style={{ fontWeight: "bold" }}>
                                Benchmarks
                                {pi.causalLink ? " (" + pi.causalLink + ")" : undefined}
                            </div>
                            <EditableText
                                obj={pi.qualitativeOverview}
                                prop="benchmarks"
                                isMarkdown={true}
                                defaultText="Benchmarks goes here"
                                style={{
                                    marginTop: 16,
                                }}
                            />
                        </div>
                    ) : undefined}
                    <div
                        style={{
                            backgroundColor: lightGreenBg,
                            padding: 32,
                            marginTop: 16,
                            borderRadius: 8,
                        }}>
                        <div style={{ fontWeight: "bold" }}>Recommendation</div>
                        <EditableText
                            obj={pi.qualitativeOverview}
                            prop="recommendation"
                            isMarkdown={true}
                            defaultText="Recommendation goes here"
                            style={{ fontStyle: "italic", marginTop: 8 }}
                        />
                    </div>

                    {editing || pi.qualitativeOverview.methodology ? (
                        <>
                            <div style={{ marginTop: 48, fontSize: 20 }}>Methodology</div>

                            <EditableText
                                obj={pi.qualitativeOverview}
                                isMarkdown={true}
                                prop="methodology"
                                defaultText="Methodology goes here"
                                style={{
                                    padding: 32,
                                    marginTop: 16,
                                }}
                            />
                        </>
                    ) : undefined}

                    {pi.quantitativeAnalysisVisible && (
                        <>
                            <div style={{ marginTop: 48, fontSize: 20 }}>Quantitative Overview</div>
                            <div style={{ marginTop: 16, padding: 32 }}>
                                <div style={{ fontWeight: "bold" }}>Quantification Analysis</div>
                                <EditableText
                                    obj={pi}
                                    prop="quantificationAnalysis"
                                    defaultText="Quantification analysis goes here"
                                    isMarkdown={true}
                                    style={{
                                        marginTop: 16,
                                    }}
                                />
                            </div>
                            {editing || pi.additionalInsights ? (
                                <div style={{ marginTop: 16, padding: 32 }}>
                                    <div style={{ fontWeight: "bold" }}>Additional Insights</div>

                                    <EditableText
                                        obj={pi}
                                        prop="additionalInsights"
                                        defaultText="Additional insights go here"
                                        isMarkdown={true}
                                        style={{
                                            marginTop: 16,
                                        }}
                                    />
                                </div>
                            ) : undefined}
                            <div style={{ marginTop: 16, marginBottom: 48 }}>
                                <WidgetAPIView
                                    name="BenchmarkAmounts"
                                    param={`${report.offering.id}:${pi.id.valueOf()}`}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: 48,
                                }}>
                                <WidgetAPIView
                                    name="ProcessItemChart"
                                    param={`${report.offering.id}:${pi.id.valueOf()}`}
                                />
                            </div>

                            {pi.displaySubProcess && pi.offering ? (
                                <>
                                    <div
                                        style={{
                                            backgroundColor: lightOrangeBg,
                                            padding: 32,
                                            marginTop: 16,
                                            borderRadius: 8,
                                        }}>
                                        <div style={{ fontWeight: "bold", marginBottom: 16 }}>
                                            Quantified sub-process
                                        </div>
                                        <EditableText
                                            obj={pi}
                                            isMarkdown={true}
                                            prop="subProcessNotes"
                                            placeholder="Notes go here"
                                        />
                                        <WidgetAPIView
                                            name="ProcessImpactBoxPlot"
                                            param={pi.offering.valueOf()}
                                        />
                                    </div>
                                </>
                            ) : undefined}

                            {pi.showBaselineImpactPathway && pi.baselineImpactPathway ? (
                                <div
                                    style={{
                                        backgroundColor: lightBlueBg,
                                        padding: 32,
                                        marginTop: 16,
                                        borderRadius: 8,
                                    }}>
                                    <div style={{ fontWeight: "bold", marginBottom: 16 }}>
                                        Baseline scenario (%)
                                    </div>
                                    <EditableText
                                        obj={pi}
                                        isMarkdown={true}
                                        prop="baselineImpactPathwayDescription"
                                    />

                                    <WidgetAPIView
                                        name="PathwayChart"
                                        param={pi.baselineImpactPathway.valueOf()}
                                    />
                                </div>
                            ) : undefined}
                            {pi.showAmountPathway && pi.amountPathway ? (
                                <div
                                    style={{
                                        backgroundColor: lightBlueBg,
                                        padding: 32,
                                        marginTop: 16,
                                        borderRadius: 8,
                                    }}>
                                    <div style={{ fontWeight: "bold", marginBottom: 16 }}>
                                        Amount pathway (%)
                                    </div>
                                    <EditableText
                                        obj={pi}
                                        isMarkdown={true}
                                        prop="amountPathwayDescription"
                                    />
                                    <WidgetAPIView
                                        name="PathwayChart"
                                        param={pi.amountPathway.valueOf()}
                                    />
                                </div>
                            ) : undefined}
                            <div
                                style={{
                                    backgroundColor: lightBlueBg,
                                    padding: 32,
                                    marginTop: 16,
                                    borderRadius: 8,
                                }}>
                                <div style={{ fontWeight: "bold" }}>
                                    Quantified Results (per {unit?.name},{" "}
                                    {quantitativeResults[0].recurring ? "recurring" : "one-off"})
                                </div>
                                <EditableText
                                    obj={pi}
                                    isMarkdown={true}
                                    prop="quantifiedResults"
                                    placeholder="Quantified results go here"
                                    style={{ fontStyle: "italic", marginTop: 8 }}
                                />
                                <table style={{ marginTop: 32 }}>
                                    <tr>
                                        <td>Average emissions generated:</td>
                                        <td style={{ minWidth: 200, textAlign: "right" }}>
                                            {thousandsSeparated.format(quantitativeResults[0].mean)}{" "}
                                            <span style={{ fontSize: 12 }}>
                                                {quantitativeResults[0].unit}
                                            </span>
                                        </td>
                                        {quantitativeResults[0].unit.includes("kg") && (
                                            <td style={{ minWidth: 150, textAlign: "right" }}>
                                                {thousandsSeparated.format(
                                                    quantitativeResults[0].mean / 1000
                                                )}{" "}
                                                <span style={{ fontSize: 12 }}>
                                                    {quantitativeResults[0].unit.replace(
                                                        "kg",
                                                        "ton"
                                                    )}
                                                </span>
                                            </td>
                                        )}
                                    </tr>
                                    {quantitativeResults.length > 1 && (
                                        <tr>
                                            <td style={{ height: 32, verticalAlign: "bottom" }}>
                                                Avoided emissions:
                                            </td>
                                        </tr>
                                    )}
                                    {quantitativeResults.slice(1).map((qr, i) => (
                                        <tr>
                                            <td>{qr.benchmark?.name} (mean):</td>
                                            <td style={{ minWidth: 200, textAlign: "right" }}>
                                                {thousandsSeparated.format(
                                                    qr.mean - quantitativeResults[0].mean
                                                )}{" "}
                                                <span style={{ fontSize: 12 }}>
                                                    {quantitativeResults[0].unit}
                                                </span>
                                            </td>
                                            {quantitativeResults[0].unit.includes("kg") && (
                                                <td style={{ minWidth: 150, textAlign: "right" }}>
                                                    {thousandsSeparated.format(
                                                        (qr.mean - quantitativeResults[0].mean) /
                                                            1000
                                                    )}{" "}
                                                    <span style={{ fontSize: 12 }}>
                                                        {quantitativeResults[0].unit.replace(
                                                            "kg",
                                                            "ton"
                                                        )}
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}

const thousandsSeparated = new Intl.NumberFormat("en-US", {
    useGrouping: true,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
})
