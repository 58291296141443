import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"

export type SaveListener = {
    discard(): Promise<void>
    save(): Promise<void>
    isDirty: boolean
}

export type EditableResource = {
    query: string
    endpoint: string
    data: any
    dtoName: string
    putDtoName: string
}

export type EditableContext = {
    readonly isDirty: boolean
    invalidate(): void
    saveListeners: Set<SaveListener>

    /** Whether we are currently in edit mode. */
    editing: boolean
    setEditing(editing: boolean): void

    save(forceDirty?: boolean): Promise<void>

    discardChanges(): Promise<void>
    addSaveListener(callback: SaveListener): void
    removeSaveListener(callback: SaveListener): void

    /** Whether e.g. scroll animations should be disabled in this context */
    disableAnimations?: boolean
}

export const DummyEditableContext: EditableContext = {
    editing: false,
    invalidate() {},
    isDirty: false,
    saveListeners: new Set(),
    setEditing(editing: boolean) {},

    async save(forceDirty?: boolean) {},

    async discardChanges() {},

    addSaveListener(callback: SaveListener) {},
    removeSaveListener(callback: SaveListener) {},

    disableAnimations: true,
}

export const EditableContext = createContext<EditableContext | null>(null)

export function useEditableContext() {
    const context = useContext(EditableContext)
    if (!context) throw new Error("No editable context")
    return context
}

/** Registers a function that will be called when the Save button is clicked. */
export function useSaveHook(save: SaveListener, ...deps: any[]) {
    const { addSaveListener, removeSaveListener } = useEditableContext()
    useEffect(() => {
        addSaveListener(save)
        return () => removeSaveListener(save)
    }, [addSaveListener, removeSaveListener, save, ...deps])
}

export type EditableOptions = {
    /** Whether this hook is enabled. Defaults to true */
    condition?: boolean
    /** Whether the current user can "set" data through this hook. Defaults to requires super user.
     * */
    canWrite?: boolean
    /** Whether the current user can "get" this hook. Defaults to true for all users. */
    canRead?: boolean
}

/**
 * Default implementation of EditableContext
 */
export function useEditableContextProvider() {
    const [version, setVersion] = useState({})
    const [editing, setEditing] = useState(false)

    const saveListeners = useRef(new Set<SaveListener>()).current

    function getIsDirty() {
        return Array.from(saveListeners).some((listener) => listener.isDirty)
    }

    const addSaveListener = useCallback((listener: SaveListener) => saveListeners.add(listener), [])
    const removeSaveListener = useCallback(
        (listener: SaveListener) => saveListeners.delete(listener),
        []
    )
    const discardChanges = useCallback(async () => {
        for (const listener of saveListeners) {
            await listener.discard()
        }
        setVersion({})
    }, [])

    const save = useCallback(async (forceDirty?: boolean) => {
        if (getIsDirty() || forceDirty) {
            // Need to snapshot the set, in case the save operation modifies
            // the set of listeners.
            const snapshotListeners = Array.from(saveListeners)
            for (const listener of snapshotListeners) {
                await listener.save()
            }
        }
        setVersion({})
    }, [])
    const invalidate = useCallback(() => setVersion({}), [])

    return {
        saveListeners,
        get isDirty() {
            return getIsDirty()
        },
        editing,
        invalidate,
        setEditing,
        addSaveListener,
        removeSaveListener,
        discardChanges,
        save,
    }
}
