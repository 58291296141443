import React from "react"
import type { Picker } from "./Picker"
import { usePerformWidgetAction, useWidgetState } from "./WidgetContext"
import { RegisterWidget } from "./WidgetView"
import { ColorStyles } from "../ui"

RegisterWidget<Picker<string>>("Picker", ({ value }) => {
    const widgetKey = value.widgetKey
    const [current, setCurrent] = useWidgetState(widgetKey, "current", value.defaultValue)
    const performAction = usePerformWidgetAction()

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                color: "rgb(46, 42, 39)",
                margin: 8,
                marginLeft: 16,
                marginRight: 16,
            }}>
            {!value.hideLabel && <div style={{ fontSize: 14, marginBottom: 6 }}>{value.label}</div>}
            <select
                disabled={value.disabled}
                value={current}
                className="form-select"
                onChange={async (e) => {
                    try {
                        await setCurrent(e.target.value)
                        if (widgetKey && value.methods?.includes("onChange"))
                            await performAction(widgetKey, "onChange")
                    } catch (err: any) {
                        alert(JSON.stringify(err))
                    }
                }}
                style={{
                    borderWidth: 1,
                    borderColor: ColorStyles.gray[200],
                    borderRadius: 8,
                    flex: 1,
                    margin: 0,
                }}>
                {value.options.map((opt) => (
                    <option value={typeof opt === "object" ? opt.value : opt}>
                        {typeof opt === "object" ? opt.display : opt}
                    </option>
                ))}
            </select>
        </div>
    )
})
