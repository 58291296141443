import React, { useContext, useState } from "react"
import ReactMarkdown from "react-markdown"
import { ClientSideLocalize, Strings } from "../../packages/localization/client-side/Dictionary"
import { Localized } from "../../packages/localization/Localized"
import { RButton } from "./Buttons"
import { ReadonlyContext } from "./ObjectContext"
import { RenderButtons, ToolButton } from "./ToolButton"
import { ColorStyles } from "../../packages/ui"

export function PropRow({
    children,
    label,
    buttons,
    badge,
    description: descriptionLocalized,
    isEmbedded,
    childrenInBadge,
    descriptionAbove,
    isReadonly,
}: {
    children?: React.ReactNode
    label?: string
    badge?: React.ReactNode
    buttons: ToolButton[]
    description?: Localized<string>
    isEmbedded?: boolean
    childrenInBadge?: boolean
    descriptionAbove?: boolean
    isReadonly?: boolean
}) {
    const readonlyContext = useContext(ReadonlyContext).mode === "readonly"

    const contextButtons = RenderButtons(buttons)
    const [showAll, setShowAll] = useState(false)

    const description = ClientSideLocalize(descriptionLocalized)

    let desc: React.ReactNode = null
    if (description) {
        const d = description.split("\n")
        desc = (
            <div
                style={{
                    flexDirection: showAll ? undefined : "row",
                    display: showAll ? undefined : "flex",
                }}>
                <ReactMarkdown>{showAll ? description : d[0]}</ReactMarkdown>
                {d.length > 1 ? (
                    <RButton
                        onClick={() => setShowAll(!showAll)}
                        style={{
                            fontSize: 12,
                            padding: 1,
                            paddingLeft: 4,
                            paddingRight: 4,
                            paddingTop: 0,
                            borderRadius: 4,
                            height: 20,
                            marginLeft: 4,
                            color: ColorStyles.primary[500],
                        }}>
                        {showAll ? Strings.Less : Strings.More}
                    </RButton>
                ) : undefined}
            </div>
        )
    }

    return (
        <div
            style={{
                marginTop: isEmbedded ? undefined : "1rem",
                width: "100%",
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                {(label || badge || childrenInBadge || contextButtons) && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyItems: "center",
                            alignItems: "center",
                            marginBottom: label ? 6 : undefined,
                            marginTop: isEmbedded ? 0 : "0.35rem",
                        }}>
                        {label && (
                            <div
                                className="reactor-form-label"
                                style={{
                                    marginRight: "0.5rem",
                                    color: ColorStyles.gray[700],
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    maxWidth: 600,
                                }}>
                                {label}
                            </div>
                        )}
                        {isReadonly && !readonlyContext && !isEmbedded && (
                            <i
                                title={`You are not allowed to modify this property. Contact an administrator to request access.`}
                                style={{ fontSize: 12, color: "#ededed" }}
                                className="fas fa-lock"></i>
                        )}
                        <div style={{ marginLeft: "0.5rem" }}>
                            {badge}
                            {childrenInBadge && children}
                        </div>
                        <div style={{ flex: 1 }} />
                        {contextButtons}
                    </div>
                )}
                {descriptionAbove && description && (
                    <div
                        className="reactor-form-small-label"
                        style={{ color: ColorStyles.gray[600], marginLeft: 8 }}>
                        {desc}
                    </div>
                )}
                {!childrenInBadge && (
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                            width: "100%",
                        }}>
                        <div style={{ flex: 1 }}>{children}</div>
                    </div>
                )}
                {!descriptionAbove && description && (
                    <div
                        className="reactor-form-small-label"
                        style={{
                            color: ColorStyles.gray[600],
                            marginTop: 6,
                            marginLeft: 8,
                            marginBottom: -16, // compensate for ReactMarkdown adding a <p>
                        }}>
                        {desc}
                    </div>
                )}
            </div>
        </div>
    )
}
