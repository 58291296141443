import React, { CSSProperties, useEffect, useState } from "react"
import { useContextualModal } from "../modal/Modal"
import { useDirtyContext } from "./DirtyContext"
import { EditableArray } from "./EditableArray"
import { EditableObject } from "./EditableObject"
import { Section } from "./Section"

/** Renders a single section.
 *
 *  Consider using `<EditableSections>` instead, to get the proper editing features.
 */
export function SectionView({
    section,
    index,
    totalCount,
}: {
    section: Section
    index: number
    totalCount: number
}) {
    const [state, setState] = useState({})

    useEffect(() => {
        const invalidate = () => {
            componentFuncs.delete(section.type)
            setState({})
        }
        let funcs = Section.invalidators.get(section.type)
        if (!funcs) {
            funcs = [invalidate]
            Section.invalidators.set(section.type, funcs)
        } else {
            funcs.push(invalidate)
        }
        return () => {
            funcs!.splice(funcs!.indexOf(invalidate), 1)
        }
    }, [section.type])

    const func = Section.types.get(section.type)

    if (func) {
        let ComponentFunc: any = componentFuncs.get(section.type)
        if (!ComponentFunc) {
            // Dynamically create a function that has the right name, for debugging purposes
            // eslint-disable-next-line no-eval
            ComponentFunc = eval(
                `((impl) => function ${section.type}(props) { 
                    return impl(props.section, props.index, props.totalCount) 
                })`
            )(func)
            componentFuncs.set(section.type, ComponentFunc)
        }

        return (
            <ComponentFunc section={section} index={index} totalCount={totalCount} state={state} />
        )
    }
    return <div>Unknown section type: {section.type}</div>
}

const componentFuncs = new Map<string, Function>()

/** Displays a set of sections, where both the list and the individual sections
 * are editable.
 *
 * Sections are objects with a `readonly type: string` field, which is used to
 * determine which component to render.
 *
 * To register a section renderer, use `RegisterSection`.
 */
export function EditableSections(props: {
    sections: Section[]
    /** Defaults to "Section", assuming this type is defined in the model */
    sectionTypeName?: string
    direction?: "column" | "row"
    itemStyle?: (index: number) => CSSProperties
    innerItemStyle?: (index: number) => CSSProperties
    itemClassName?: string | ((index: number) => string)
}) {
    const { setDirty } = useDirtyContext()
    const typeName = props.sectionTypeName ?? "Section"
    const { modal, showModal } = useContextualModal<Section | undefined>()

    if (!(props.sections instanceof Array)) {
        throw new Error("sections must be an array")
    }

    return (
        <>
            {modal}
            <EditableArray
                arr={props.sections}
                direction={props.direction ?? "column"}
                itemClassName={props.itemClassName}
                itemStyle={props.itemStyle}
                itemTypeName={typeName}
                createInstance={async () => {
                    const { PickEditingTemplate } = await import("./EditingTemplatePicker")
                    return PickEditingTemplate(typeName, showModal)
                }}>
                {(section, index) => (
                    <EditableObject
                        obj={section}
                        key={index}
                        typeName={typeName}
                        style={props.innerItemStyle?.(index)}
                        actions={{
                            deleteThis() {
                                props.sections.splice(index, 1)
                                setDirty()
                            },
                            async saveAsTemplate() {
                                const { SaveAsEditingTemplate } = await import(
                                    "./EditingTemplatePicker"
                                )
                                await SaveAsEditingTemplate(typeName, section, showModal)
                            },
                        }}>
                        <SectionView
                            section={section}
                            index={index}
                            totalCount={props.sections.length}
                        />
                    </EditableObject>
                )}
            </EditableArray>
        </>
    )
}
