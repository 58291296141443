import { v4 as uuidv4 } from "uuid"
import type { OpaqueString } from "../Opaque"
import type { Type } from "../Type"
import Prando from "prando"

/** Opaque string. Represents a globally unique identifier. */
export type Uuid<TClass extends string = "Uuid"> = OpaqueString<TClass>

/** Creates a new random Uuid, or casts an existing string to Uuid. */
export function Uuid<TClass extends string = "Uuid">(value?: string): Uuid<TClass> {
    if (value) return value as any as Uuid<TClass>
    return uuidv4() as any as Uuid<TClass>
}

/**
 * Generates a UUID (v4) from a seed string. This is useful in testing, for
 * generating a UUID deterministically from a string, which in turn e.g. is used
 * for snapshot testing. The generated UUID is guaranteed to be the same for the
 * same seed string.
 *
 * This is NOT suitable for generating UUIDs for production use. UUIDs are meant
 * to be Universally Unique Identifiers, and introducing a seed value
 * contradicts this concept because it would cause the same "random" values to
 * be produced under the same seed.
 *
 */
Uuid.fromSeed = <TClass extends string = "Uuid">(seed: string): Uuid<TClass> => {
    const rng = new Prando(seed)
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (rng.next() * 16) | 0
        const v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    }) as any as Uuid<TClass>
}

/** UUID pattern: 8-4-4-4-12 hexadecimal digits separated by hyphens */
Uuid.pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

/** Checks that the provided string is a valid Uuid, as produced by Uuid().
 *
 *  It must match the pattern in `Uuid.pattern`.
 */
Uuid.validate = (value: string | Uuid): value is Uuid => {
    return Uuid.pattern.test(value.valueOf())
}

export function IsUuidType(type: Type): boolean {
    return typeof type === "object" && type.alias === "Uuid"
}
