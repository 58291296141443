import { OpaqueInteger } from "../Opaque"

/** A number that should be an integer (whole number). */
export type Integer = OpaqueInteger<"Int">

/** Converts the provided number to an Integer.
 *
 *  Throws an error if the provided number is not an integer
 */
export function Integer(int: number): Integer {
    if ((int | 0) !== int) {
        throw new Error("Not an integer: " + int)
    }
    return int as any
}
