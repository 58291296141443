import { IsNode } from "../../reactor/AssertNode"
import { server } from "../../server"
import { defaults, FileRecord, FileShareSetting } from "../../studio/client"
import type { Folder } from "./Folders"

/**
 * Uploads a file to Reactor using the /api/files endpoint.
 */
export async function UploadFile(
    /** The ID or name of the target folder.
     *
     *  Folder names can only be used for top-level folders, e.g. "Media". If
     *  the folder name is ambiguous, an error will be thrown.
     */
    folder: Folder["id"] | Folder["name"],
    /** The file to upload, or string contents of the file. If a string is
     *  provided, it will be uploaded as a text/plain file by default. Set the
     *  mimetype parameter to override
     */
    file: File | string,
    /** The mimetype of the file. If not provided, `file.type` will be used.
     *
     *  For string uploads, this defaults to `text/plain`.
     */
    mimetype: string = typeof file === "string" ? "text/plain" : file.type,

    /**
     * Whether the file should be temporary. If `true`, the file
     * will be deleted by an interval job as long as it is not associated with a collection entity.
     */
    temporaryFile = false,

    share?: FileShareSetting | "FolderDefault",

    fileName?: string
) {
    const data = new FormData()
    if (typeof file === "string") {
        data.append("file", new Blob([file], { type: mimetype }), fileName)
    } else {
        data.append("file", file, fileName)
    }

    try {
        const query: any = {
            mimetype,
            temporaryFile: temporaryFile.toString(),
            share,
            originalFileName: fileName,
        }
        const r = await fetch(
            `${server()}/api/folders/${folder}/files?${Object.keys(query)
                .map((key) => (query[key] ? `${key}=${encodeURIComponent(query[key])}` : undefined))
                .filter((x) => !!x)
                .join("&")}`,
            {
                method: "POST",
                body: data as any,
                headers: {
                    Authorization: defaults.headers.Authorization,
                },
            }
        )

        if (r.status !== 200) {
            throw await r.json()
        }
        const j = (await r.json()) as { files: FileRecord[] }
        return j.files[0].id
    } catch (e: any) {
        if ("message" in e) alert(e.message)
        if ("detail" in e) alert(e.detail)
        else alert(JSON.stringify(e))
    }
}
