import React from "react"
import { EditableText } from "../../../../packages/editing/EditableText"
import { Section } from "../../../../packages/editing/Section"
import { Markdown } from "../../../../reactor/Types/Primitives/Markdown"

Section(HeadingAndTextSection)
/**
 * @zoom 3
 */
function HeadingAndTextSection(section: {
    /**
     * @default Heading
     */
    heading: string
    /** Whether this is an extra large heading, e.g. key figure  */
    largeHeading: boolean
    /**
     * @default "Content goes here"
     */
    body: Markdown

    /**
     * How text is aligned within the section
     * @default "Center"
     */
    alignment: "Left" | "Center" | "Right"

    /**
     * Max width of the section
     */
    maxWidth?: number
}) {
    return (
        <div
            style={{
                width: "100%",

                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 64,
                paddingBottom: 64,
                maxWidth: section.maxWidth,
            }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 1280,
                    width: "100%",

                    alignItems:
                        section.alignment === "Center"
                            ? "center"
                            : section.alignment === "Left"
                            ? "flex-start"
                            : "flex-end",
                }}>
                <h1
                    className={section.largeHeading ? "display-2" : undefined}
                    style={{ whiteSpace: section.largeHeading ? "nowrap" : undefined }}>
                    <EditableText obj={section} prop="heading" />
                </h1>
                <div
                    style={{
                        marginTop: 16,
                        lineHeight: 1.5,

                        textAlign:
                            section.alignment === "Center"
                                ? "center"
                                : section.alignment === "Left"
                                ? "left"
                                : "right",
                    }}>
                    <EditableText obj={section} prop="body" isMarkdown={true} />
                </div>
            </div>
        </div>
    )
}
