import React, { CSSProperties } from "react"
import { Button } from "./Button"

export const colors = {
    peach: "#FEA97C",
    darkGreen: "#0B3540",
    green: "#3c665b",
    mediumGreen: "#76D6BA",
    lightGreen: "#c7e6dd",
    foreground: "#2e2a27",
    white: "white",
    danger: "#b63",
    overlayGreen: "#0B354044",
    overlayGreenHover: "#0B354000",
    subtleHover: "#0B354011",

    primaryGreen: "#61A391",
    orange: "#F2994A",
    blue: "#53B1FE",
    red: "#EB5757",
}

export const headerStyle: CSSProperties = {
    fontSize: 36,
    fontWeight: "bold",
    color: "#0B3540",
}

export function Arrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.977"
            height="15.792"
            viewBox="0 0 15.977 15.792">
            <path
                id="arrow-right"
                d="M7.778,34.711l-.706.706a.428.428,0,0,0,0,.605L12.6,41.555H.428A.428.428,0,0,0,0,41.983v1a.428.428,0,0,0,.428.428H12.6L7.072,48.941a.428.428,0,0,0,0,.605l.706.706a.428.428,0,0,0,.605,0l7.468-7.468a.428.428,0,0,0,0-.605L8.383,34.711A.428.428,0,0,0,7.778,34.711Z"
                transform="translate(0 -34.586)"
                fill="#6ec1a8"
            />
        </svg>
    )
}

export function CloseX() {
    return (
        <svg
            fill="none"
            height="24"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg">
            <line x1="18" x2="6" y1="6" y2="18" />
            <line x1="6" x2="18" y1="6" y2="18" />
        </svg>
    )
}

export const fontSizes = {
    body: 20,
    header: 36,
}

export function Title(props: { title: string; cta?: { text: string; onClick: () => void } }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "2rem",
                paddingBottom: "1rem",
                alignItems: "flex-end",
            }}>
            <div style={{ ...headerStyle, alignSelf: "flex-start" }}>{props.title}</div>

            {props.cta && (
                <div
                    style={{
                        marginLeft: 32,
                    }}>
                    <Button text={props.cta.text} onClick={props.cta.onClick} />
                </div>
            )}
        </div>
    )
}
