import React from "react"
import { colors } from "../styles"
import { Link } from "react-router-dom"
import { EditableObject } from "../../../../packages/editing/EditableObject"
import { EditableImage } from "../../../../packages/editing/EditableImage"
import { EditableText } from "../../../../packages/editing/EditableText"
import { EditableArray } from "../../../../packages/editing/EditableArray"
import { useEditableContext } from "../../../../packages/editing/EditableContext"
import { useMenuContext } from "../MenuContext"
import { Image } from "../../../../reactor/Types/File"
import { Section } from "../../../../packages/editing/Section"

Section("HeaderSection", HeaderSection)
export function HeaderSection(section: {
    image?: Image
    size: "full" | "compact"
    logoText?: string
    title?: string
    /** How much the image should scroll in percent as the page is scrolled.
        Adjust this and test in different screen sizes to ensure the image
        always covers the entire section.
     */
    parallaxAmount?: number
    /** If set, the logo will displayed large and in the middle.
     *  Otherwise, the logo will be displayed in the top-left corner
     */
    isHome?: boolean
}) {
    const menu = useMenuContext()
    const { editing } = useEditableContext()

    const margin = section.size === "full" ? 32 : 22

    if (!menu) return <></>

    return (
        <EditableImage
            obj={section}
            options={{ width: 1920, format: "jpeg" }}
            prop="image"
            style={{
                height: section.size === "full" ? 500 : 90,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: `50% calc(50%)`,
                backgroundRepeat: "no-repeat",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
            }}>
            <div
                style={{
                    width: "100%",
                    height: 200,
                    position: "absolute",
                    bottom: 0,
                    background:
                        section.size === "full"
                            ? `linear-gradient(0deg, ${colors.darkGreen} 0%, ${
                                  colors.darkGreen + "00"
                              } 100%)`
                            : colors.darkGreen,
                }}
            />
            {!section.isHome && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "absolute",
                        top: margin,
                        left: margin,
                        color: "white",
                        fontSize: 36,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}>
                    <EditableImage
                        obj={menu}
                        prop="logo"
                        link="/"
                        style={{
                            width: 48,
                            height: 48,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            marginRight: 24,
                        }}
                        options={{
                            height: 48,
                        }}
                    />
                    {section.size === "compact" && (
                        <EditableText obj={section} prop="logoText" link="/" />
                    )}
                </div>
            )}
            {menu && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        fontWeight: "normal",
                        fontSize: 20,
                        color: "white",
                        textShadow: "3px 3px 4px #0008",
                        position: "absolute",
                        top: margin,
                        right: margin,
                    }}>
                    <EditableArray arr={menu.items} direction="row" itemTypeName="MenuItem">
                        {(item) => {
                            let text = (
                                <EditableText
                                    obj={item}
                                    prop="title"
                                    style={{ padding: 10, cursor: "pointer" }}
                                />
                            )
                            if (item.link && !editing) {
                                text = (
                                    <a
                                        onClick={(e) => {
                                            if (item.link?.startsWith("https://")) {
                                                window.open(item.link, "_blank")
                                                e.preventDefault()

                                                return false
                                            }
                                        }}
                                        style={{ color: "white", textDecoration: "none" }}
                                        href={item.link}>
                                        {text}
                                    </a>
                                )
                            }
                            return (
                                <EditableObject obj={item} typeName="MenuItem" actions={{}}>
                                    {text}
                                </EditableObject>
                            )
                        }}
                    </EditableArray>

                    <Link
                        to="/studio"
                        style={{
                            cursor: "pointer",
                            padding: 10,
                            color: "white",
                            textDecoration: "none",
                        }}>
                        Log in
                    </Link>
                </div>
            )}

            <div style={{ zIndex: 1, marginTop: section.isHome ? undefined : 200 }}>
                {section.isHome && (
                    <EditableImage
                        obj={menu}
                        prop="logo"
                        style={{
                            backgroundSize: "contain",
                            height: 160,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                        }}
                        options={{
                            height: 160,
                        }}
                    />
                )}
                {section.size === "full" && (
                    <EditableText
                        link="/"
                        obj={section}
                        prop="logoText"
                        defaultText="ClimatePoint"
                        style={{
                            marginTop: 24,
                            color: "white",
                            cursor: "pointer",
                            fontSize: 55,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    />
                )}
                {section.size === "full" && (
                    <EditableText
                        obj={section}
                        prop="title"
                        style={{
                            color: colors.mediumGreen,
                            fontSize: 32,
                            textAlign: "center",
                        }}
                    />
                )}
            </div>
        </EditableImage>
    )
}
