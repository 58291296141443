import React from "react"
import type { Marked, MarkedProps } from "./Marked"
import { RegisterWidget } from "./WidgetView"

RegisterWidget<Marked>("Marked", ({ value }) => <MarkedWidget {...value} />)

export function MarkedWidget({ text, mark }: MarkedProps) {
    return (
        <div
            style={{ fontSize: 14, padding: 3 }}
            dangerouslySetInnerHTML={{
                __html: text.replace(
                    new RegExp(mark, "i"),
                    (match) => `<mark style="margin: 0; padding: 0;">${match}</mark>`
                ),
            }}
        />
    )
}
